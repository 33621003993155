import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useCallback, useMemo, useState } from "react";
import { ConditionalFragment } from "react-conditionalfragment";
import { useTranslation } from "react-i18next";
import { Button, ButtonGroup, Col, Modal, ModalBody, ModalFooter, ModalHeader, ModalProps, Row, Table, Card, CardBody, Collapse, InputGroupAddon } from "reactstrap";
import { getQuestionTypeCategory, QuestionType, QuestionTypeCategory, questionTypeDisplayName } from "../../../api/main/models/codeOnly/QuestionType";
import { LoadingIndicator } from "../../shared/LoadingIndicator";
import { NoResultsFound } from "../../shared/NoResultsFound";
import { SearchInput } from "../../shared/searchInput/SearchInput";
import "./selectQuestionModal.scss";
import { QuestionTagDisplay } from "../../questionTags/questionTagSelector/QuestionTagDisplay";
import { useQuestionsListViewModel } from "../../../api/main/questions/viewModels/useQuestionsListViewModel";
import * as React from 'react';
import { TagDisplay } from "../../shared/tagFilter/TagDisplay";
import { useToggleState } from 'use-toggle-state';

export interface SelectQuestionModalCloseEventArgs {
    /**
     * All currently selected ids.
     * */
    selectedIds: Array<string>,

    /**
     * Added Ids (those in selectedIds that were not in initialSelectedIds).
     */
    addedIds: Array<string>,
    /**
     * Removed Ids (those in selectedIds that were not in initialSelectedIds).
     */
    removedIds: Array<string>,


    cancelled: boolean,
}

export interface SelectQuestionModalProps extends ModalProps {
    isOpen: boolean,
    toggle: () => void,
    isLoadingQuestions?: boolean,

    onClose: (event: SelectQuestionModalCloseEventArgs) => void,

    initialSelectedIds?: Array<string>,

    questionTypeCategories?: Array<QuestionTypeCategory>,
}

/**
 * Modal that allows the user to select from a list of questions.
 * @param props
 */
export const SelectQuestionModal = (props: SelectQuestionModalProps) => {
    const {
        isOpen,
        toggle,

        isLoadingQuestions,
        onClose,
        questionTypeCategories = [],

        initialSelectedIds,
    } = props;
    const { t } = useTranslation();

    const isEdit = !!initialSelectedIds;
    const { data: {items: allItems, tags, links, driverMetrics } } = useQuestionsListViewModel({ pageSize: undefined });
    
    // Manage the questions that have been selected.
    const [selectedQuestions, setSelectedQuestions] = useState<Array<string>>(initialSelectedIds ?? []);
    const toggleSelection = useCallback((id: string) => {
        setSelectedQuestions(prevState => {
            const existing = prevState.find(it => it === id);
            if (existing) {
                return prevState.filter(it => it !== id);
            } else {
                return [
                    ...prevState,
                    id,
                ];
            }
        });
    }, [setSelectedQuestions]);
    const isSelected = useCallback((id: string) => !!selectedQuestions.find(it => it === id), [selectedQuestions]);

    // Close the modal and return the results.
    const closeModal = useCallback((event?: { selectedIds: Array<string>, cancelled?: boolean, }) => {
        if (onClose) {
            const selectedIds = event?.selectedIds ?? selectedQuestions;

            const externalEvent: SelectQuestionModalCloseEventArgs = {
                ...(event ?? {}),

                selectedIds: selectedIds,
                cancelled: event?.cancelled ?? false,

                addedIds: event?.cancelled? []: selectedIds.filter(id => !initialSelectedIds?.find(it => it === id)),
                removedIds: event?.cancelled ? []: initialSelectedIds?.filter(id => !selectedIds.find(it => it === id)) ?? [],
            };
            onClose(externalEvent);
        }

        // Close the modal.
        toggle();
    }, [selectedQuestions, toggle, onClose, initialSelectedIds]);

    // Manage the toggling of tags on and off.
    const [selectedTags, setSelectedTags] = React.useState<Array<string>>([]);
    const toggleTagSelected = useCallback((tag: string) => {
        setSelectedTags(prevState => {
            const existing = prevState.find(it => it === tag);

            if (existing) {
                // If we are currently on, toggle to off.
                return prevState.filter(it => it !== tag);
            } else {
                // Add tag to the list of on toggles.
                return [...prevState, tag];
            }
        });
    }, [setSelectedTags]);
    const isTagSelected = React.useCallback((tag: string) => {
        // If no tags are selected, treat it as if all tags are selected.
        if (!selectedTags.length) {
            return true;
        }

        // Return true or false based on if this tag has been selected explictly.
        return selectedTags.find(it => it === tag);
    }, [selectedTags]);

    // Filtering the display between all and selected only.
    const [filter, setFilter] = useState<'all' | 'selected'>('all');

    // Filtering of questions.
    const [search, setSearch] = useState<string>('');

    // Filter by the assessment's search client side so it can work when offline as well as online.
    const items = useMemo(() => {
        if (!allItems) {
            return allItems;
        }

        let ret = allItems;

        // Filter by selected tags if any tag filters have been selected.
        if (selectedTags.length) {
            ret = ret.filter(item => {
                // Get all questionFilterTags for this question.
                const myLinks = links.filter(link => link.targetId === item.id);

                for (const link of myLinks) {
                    if (isTagSelected(link.questionTagId)) {
                        return true;
                    }
                }

                return false;
            });
        }


        // Filter by question type categories if any are provided.
        if (questionTypeCategories !== null && questionTypeCategories.length > 0) {
            ret = ret.filter(item => questionTypeCategories.filter(it => it === getQuestionTypeCategory(item.questionType as QuestionType)).length === 1);
        } else {
            // If we arent specifying a filter, only show specifically questions
            ret = ret.filter(item => getQuestionTypeCategory(item.questionType as QuestionType) === QuestionTypeCategory.Question);
        }

        // Filter by the selected filter.
        switch (filter) {
            case 'all':
                break;
            case 'selected': {
                ret = ret.filter(item => selectedQuestions.find(it => it === item.id));
            }
        }

        // Filter by the user's search text.
        let lowerSearch = search.toLocaleLowerCase();
        if (lowerSearch) {
            // Filter the items being displayed.
            ret = ret.filter(item => {
                // Get the primary driver metric.
                const driverMetricName = driverMetrics?.find(it => it.id === item.driverMetricId)?.name
                    ?? t('questionsListBase.driverMetric.none', '(No primary safety metric)');

                // Get the tags that are linked to this item.
                const myTags = tags?.filter(tag => !!links?.find(it => it.questionTagId === tag.id && it.targetId === item.id)) ?? [];

                return (
                    item.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0
                    || questionTypeDisplayName(item.questionType as QuestionType, t).toLocaleLowerCase().indexOf(lowerSearch) >= 0
                    || !!myTags.find(tag => tag.name.toLocaleLowerCase().indexOf(lowerSearch) >= 0)
                    || (driverMetricName ?? '').toLocaleLowerCase().indexOf(lowerSearch) >= 0
                );
            });
        }

        return ret;

        
    }, [allItems, search, filter, selectedQuestions, questionTypeCategories, links, tags, isTagSelected, selectedTags.length, driverMetrics, t]);

    //controls for filtering by tags
    const [isTagFilterOpen, toggleTagFilterOpen] = useToggleState(false);

    // UI
    //
    const colCount = 6;

    return (
        <Modal isOpen={isOpen} toggle={() => toggle()} size="xl" className="select-question-modal">
            <ModalHeader toggle={() => toggle()}>
                {
                    t('selectQuestionModal.heading.default', 'Select questions')
                }
            </ModalHeader>
            <ModalBody>
                <Row>
                    <Col>
                        <SearchInput value={search} onChange={e => setSearch(e.currentTarget.value)}>
                        <InputGroupAddon addonType="append">
                            <Button className="search-input-button" onClick={() => toggleTagFilterOpen()}>
                                <FontAwesomeIcon icon="tags" />
                                <span className="sr-only">{t('questionsListBase.toggleTags', 'Toggle tags')}</span>
                            </Button>
                        </InputGroupAddon>
                        </SearchInput>
                    </Col>
                    <Col xs="auto">
                        <ButtonGroup>
                            <Button color="secondary" outline={filter !== 'all'} onClick={() => setFilter('all')}>
                                {t('subscriptionAssessmentsListBase.filter.all', 'All')}
                            </Button>
                            <Button color="secondary" outline={filter !== 'selected'} onClick={() => setFilter('selected')}>
                                {t('subscriptionAssessmentsListBase.filter.selected', 'Selected')}
                            </Button>
                        </ButtonGroup>
                    </Col>
                </Row>

                <Collapse isOpen={isTagFilterOpen}>
                    <Card className="form-cards">
                        <CardBody>
                            <Row>
                                <Col xs={12} md="auto">
                                    <p className="text-muted">
                                        {t('questionsListBase.selectTagsToFilter', 'Select tags to filter by:')}
                                    </p>
                                </Col>
                                <Col>
                                    <TagDisplay noPadding>
                                        {
                                            tags?.map(item => (
                                                <Button key={item.id} size="sm" color="secondary" outline={!isTagSelected(item.id)} onClick={() => toggleTagSelected(item.id)}>
                                                    {item.name}
                                                </Button>
                                            ))
                                        }
                                    </TagDisplay>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>
                </Collapse>

                <Table className="select-question-modal-table" responsive striped>
                    <thead>
                        <tr>
                            <th>&nbsp;</th>
                            <th className="d-table-cell d-lg-none">{t('selectQuestionModal.question', 'Question')}</th>
                            <th className="d-none d-lg-table-cell">{t('selectQuestionModal.name', 'Name')}</th>
                            <th className="d-none d-lg-table-cell">{t('selectQuestionModal.questionType', 'Question type')}</th>
                            <th className="d-none d-lg-table-cell">{t('selectQuestionModal.safetyMetric', 'Safety metric')}</th>
                            <th className="d-none d-lg-table-cell">{t('selectQuestionModal.tags', 'Tags')}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            items?.map(item => {
                                const itemIsSelected = isSelected(item.id);

                                return (
                                    <tr key={item.id} onDoubleClick={e => closeModal({ selectedIds: [...selectedQuestions.filter(it => it !== item.id), item.id] })}>
                                        <td style={{ width: '1px' /* Sizes to fit contents */ }}>
                                            <ButtonGroup>
                                                <Button color="secondary" outline={!itemIsSelected} onClick={() => toggleSelection(item.id)}>
                                                    {
                                                        itemIsSelected ? t('selectQuestionModal.selected', 'Selected')
                                                            : t('selectQuestionModal.select', 'Select')
                                                    }
                                                </Button>
                                                <ConditionalFragment showIf={itemIsSelected}>
                                                    <Button color="secondary" outline={!isSelected} onClick={() => toggleSelection(item.id)}>
                                                        <FontAwesomeIcon icon="times" />
                                                    </Button>
                                                </ConditionalFragment>
                                            </ButtonGroup>
                                        </td>
                                        <td className="d-table-cell d-lg-none">
                                            <div>{item.name}</div>
                                        </td>
                                        <td className="d-none d-lg-table-cell">{item.name}</td>
                                        <td className="d-none d-lg-table-cell">{questionTypeDisplayName(item.questionType as QuestionType, t)}</td>
                                        <td className="d-none d-lg-table-cell">{driverMetrics?.find(it => it.id === item.driverMetricId)?.name ?? t('selectQuestionModal.driverMetric.none', '(No primary safety metric)')}</td>
                                        <td className="d-none d-lg-table-cell">
                                            <QuestionTagDisplay
                                                tags={tags?.filter(tag => !!links?.find(it => it.questionTagId === tag.id && it.targetId === item.id)) ?? []}
                                            />
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        <ConditionalFragment showIf={!!isLoadingQuestions && !items?.length}>
                            <tr><td colSpan={colCount}><LoadingIndicator fullWidth /></td></tr>
                        </ConditionalFragment>
                        <ConditionalFragment showIf={!isLoadingQuestions && !items?.length}>
                            <tr><td colSpan={colCount}>
                                <NoResultsFound search={search} />
                            </td></tr>
                        </ConditionalFragment>
                    </tbody>
                </Table>
            </ModalBody>
            <ModalFooter>
                <ConditionalFragment showIf={!!selectedQuestions.length}>
                    <Button color="primary" onClick={() => closeModal()}>
                        {
                            isEdit ? t('selectQuestionModal.useSelected.edit', 'Use {{count}} selected questions', { count: selectedQuestions.length })
                                : t('selectQuestionModal.useSelected.add', 'Add {{count}} selected questions', { count: selectedQuestions.length })
                        }
                    </Button>
                    <> </>
                </ConditionalFragment>
                <Button color="primary" outline onClick={() => closeModal({ selectedIds: [], cancelled: true })}>
                    {t('common.cancel', 'Cancel')}
                </Button>
            </ModalFooter>
        </Modal>
    );
};